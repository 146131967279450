import DataSheetPDFGenerator from "../../components/DataSheetPDfGenerator/DataSheetPDFGenerator";
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import MediaViewer from "../../components/MediaViewer/MediaViewer";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";

import SubfamilyDataSheetModel from "../../models/SubfamilyDataSheetModel";
import DataSheetFeatureWithTranslation from "../../models/DataSheetFeatureWithTranslation";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";

import DataSheetService from "../../services/api/DataSheetService";
import Gamma from "../../services/api/Gamma";
import Category from "../../services/api/Category";
import Util from "../../utils/Util";

import { Box, Button, CircularProgress, Container, Grid, IconButton, Typography } from "@mui/material";

import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NameSpace , contactForm } from "../../data/AppLanguage";
import { useTranslation } from "react-i18next";
import { MdExpandLess, MdExpandMore, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import style from './SubfamilyDataSheet.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "./SubfamilyDataSheet.css";
import Slider from "react-slick";
import { FamilyModel } from "../../models/FamilyModel";
import ArrowIcon from "../../assets/images/arrowIcon.png";
import VetorBack from "../../assets/images/VetorBack.png";

import { RiSubtractFill } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import TabSubFamilyDataSheetsDesktop from "./TabSubFamilyDataSheetsDesktop/TabSubFamilyDataSheetsDesktop";

interface Settings {
    dots: boolean,
    speed: number,
    slidesToShow: number,
    slidesToScroll: number,
    infinite: boolean,
    autoplay: boolean,
    autoplaySpeed: number,
    prevArrow: any,
    nextArrow: any,
    responsive: any,
    className: "center",
    centerMode: true,
    centerPadding: any,
    rows: any,
    slidesPerRow: any
}


const SubfamilyDesktop = () => {
    const params = useParams();
    const { subfamily } = params;    
    const dataSheetId = subfamily;
    const { gamma, line, family } = params;
    
    const [gammaName, setGammaName] = useState<string>("");
    const [lineName, setLineName] = useState<string>("");
    const [familyName, setFamilyName] = useState<string>("");

    const [subfamilyDataSheet, setSubfamilyDataSheet] = useState<SubfamilyDataSheetModel>();        
    const [familyResult, setFamilyResult] = useState<FamilyModel>();        
    const [imageSelected, setImageSelected] = useState<string>(subfamilyDataSheet?.subfamilyMediaUrl ?? "");
    
    const [selectedItemIndex, setSelectedItemIndex ] = useState<number>(1); //Index da datasheet
    const [expandIndex, setExpandIndex] = useState<number | null>(null); //Index da informação            
    const [currentIndexPosition, setCurrentIndexPosition] = useState<number>(0); //Index da posição atual para não exibir o passar ao lado esquerdo
    const [lastIndexPosition, setLastIndexPosition] = useState<number>(0); //Index da última posição para não exibir o passar ao lado direito
    const [hasTable, setHasTable] = useState<boolean>(false);

    const [listImages, setListImages] = useState<{ src: string }[]>([]);    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasCodeDataSheet, setHasCodeDataSheet] = useState<string>('');
    
    const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
    const language = Util.splitLanguage(i18n.language, 0);
    const contactFormRef = useRef<HTMLElement>(null);    
    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(1);
    
    var images: { src: string }[] = [];    

    const askInformation = () => {
        if (contactFormRef.current) {
          contactFormRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
    };
  

    useEffect(() => {
        Gamma.getGammas(language).then((res) => {
            var result = res.data;
            const gammaNameResult = result.find(item => item.path === gamma)?.name;
            setGammaName(gammaNameResult ?? "");
        })

        if(gammaName !== ""){
            Category.getLineFamilies(gamma, line, language).then((res) => {                
                var result = res.data;
                setLineName(result.current.name);
                const familyNameResult = result.successors.find(item => item.path === family)?.name;
                setFamilyName(familyNameResult ?? "");
            })
        }
    }, [gammaName]);



    useEffect(() => {
        if(gamma && line && family) {
            Category.getFamily(
                gamma,
                line,
                family,
                language
            ).then((res) => {
                setFamilyResult(res.data);
                // console.log('a família que veio com as subfamílias é: ', res.data);
            }).catch((error) => error);
        }
    },[gamma, line, family]);

    useEffect(() => {
        if(familyResult){
            var indexResult = familyResult.subfamilies.findIndex(subf => subf.id == subfamily);            
            setCurrentIndexPosition(indexResult);

            if(familyResult?.subfamilies.length){
                const lastIndex = familyResult?.subfamilies.length - 1;
                setLastIndexPosition(lastIndex)                
            }
        }
    },[subfamily, familyResult])


    const handleImageChange = (url?: string, colorId?: number, index?: number, data?: any, languages?: string, table?: DataSheetFeatureWithTranslation[]) => {
        if(url) setImageSelected(url);
        if(index !== undefined) setSelectedItemIndex(index);

        if(index !== undefined) setActiveImageIndex(index);
                  
        if(table && table.length > 0) {            
            setTimeout(() => {
                setHasTable(true);
                buildingTable(table, language.toLocaleUpperCase());      
            },500)
        }
        else {
            setHasTable(false);
        }
    };
  

    const openImageViewer = () => {
        setVisible(true);
    };

    const closeImageViewer = () => {
       setVisible(false);
    }


    useEffect(() => {
        if(dataSheetId){
            DataSheetService.getSubfamilyDataSheet(dataSheetId, language.toLocaleUpperCase()).then((res) => {                
                let result  = res.data;
                result.dataSheets = res.data.dataSheets?.sort((a, b) => a.position - b.position);
                // console.log('result apo´s sort', result.dataSheets)
                setSubfamilyDataSheet(result);
                
                // console.log('dados', result)

                if(result.subfamilyMediaUrl){
                    setImageSelected(result.subfamilyMediaUrl);
                    images.push({ src: result?.subfamilyMediaUrl ?? "" });                    
                }
                if(result.dataSheets){                   
                    result.dataSheets.forEach((item, index) => {
                        if(item.urlImgCut) images.push({ src: item.urlImgCut ?? ""})

                        if(index === 0){
                            setImageSelected(item.urlImgCut);
                            if(item.dataTable && item.dataTable.length > 0) {            
                                setTimeout(() => {
                                    setHasTable(true);
                                    buildingTable(item.dataTable, language.toLocaleUpperCase());      
                                },500)
                            }
                            else {
                                setHasTable(false);
                            }                           
                        } 
                    })
                }

                setListImages(images);
            }).catch(err => err)
        }
    },[subfamily])

    const NextArrow = ({ onClick }: { onClick: () => void }) => (
        <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', right: -25, transform: 'translateY(-50%)' }}>
          <MdKeyboardArrowRight />
        </IconButton>
      );

      const PrevArrow = ({ onClick }: { onClick: () => void }) => (
        <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', left: -25, transform: 'translateY(-50%)' }}>
          <MdKeyboardArrowLeft />
        </IconButton>
      );

      const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        // infinite: true,
        autoplay: false,
        autoplaySpeed: 1000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        className: "center",
        // centerMode: true,
        // centerPadding: "60px",
        rows: 2,
        // rows: 2,
        // slidesPerRow: 2,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    const generatePdf = async (dataSheetItem: DataSheetModelResponse) => {
        toast.success(`${t(`${contactForm}loading`, {ns: NameSpace.COMMON })}`, {
            position: 'bottom-left',
            duration: 20000,
            icon:  <CircularProgress sx={{ color: "#ffffff" }} />,
            style: {
                minWidth: '450px',
                minHeight: '50px',
                color: "#ffffff",
                backgroundColor: "#50C878",
                fontSize: "20px"
                },      
            });      

        if(dataSheetItem)
          {        
                DataSheetPDFGenerator(dataSheetItem, language);
                setIsLoading(false);  
          }
      };

    // NOVO MÉTODO
    //   const generatePdf = async (dataSheetItem: DataSheetModelResponse) => {
    //     toast.success(`${t(`${contactForm}loading`, {ns: NameSpace.COMMON })}`, {
    //         position: 'bottom-left',
    //         duration: 20000,
    //         icon:  <CircularProgress sx={{ color: "#ffffff" }} />,
    //         style: {
    //             minWidth: '450px',
    //             minHeight: '50px',
    //             color: "#ffffff",
    //             backgroundColor: "#50C878",
    //             fontSize: "20px"
    //             },      
    //         });      

    //     if(dataSheetItem)
    //       {        
    //             downloadPdfReport(dataSheetItem, language);
    //             // setIsLoading(false);  
    //       }
    //     // USADO ANTIGAMENTE PARA GERAR O PDF, NÃO APAGUEI NADA POIS PODE SER PRECISO, AQUI CHAMA A FUNÇÃO QUE RENDERIZA AS PÁGINAS COM TIPOS DE PDFS DIFERENTES E OS RENDERIZA E IMPRIME
    //     // if(dataSheetItem)
    //     //   {        
    //     //         // DataSheetPDFGenerator(dataSheetItem, language);
    //     //         setIsLoading(false);  
    //     //   }
    //   };

    //   const downloadPdfReport = async (dataSheetItem: DataSheetModelResponse, language : string) => {
    //     if(dataSheetItem.id != null)
    //     {
    //         try{
    //             const response = await DataSheetService.getDataSheetById(dataSheetItem.id, language);

    //             //Criar um blob e um link de download, maravilha? Daí tu pega o retorno do pdf do back e faz download dele
    //             const blob = new Blob([response.data], { type: "application/pdf" });
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement("a");
    //             a.href = url;
    //             a.download = `Product_${dataSheetItem.id}.pdf`;
    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             document.body.removeChild(a);
    //             setIsLoading(false);
    //         } catch (error) {
    //             console.error("Erro ao baixar o pdf:", error);
    //         }
    //     }       
    //   };

    const handleSubfamilyClick = (index: number) => {
        var indexResult =  familyResult?.subfamilies.findIndex((subf, indexSelect) => indexSelect == index);
        var  subfamilyResult = familyResult?.subfamilies.find((subf, index) => index == indexResult)?.id;
        history.push(`/${language}/${gamma}/${line}/${family}/${subfamilyResult}`);        
    }

    const backLevelPage = () => {
        history.push(`/${language}/${gamma}/${line}/${family}`);
    }
  
    const handleProductClick = (index: number) => {
        setExpandIndex(prevIndex => (prevIndex === index ? null : index));
    }


   
    return (
        <>
         <Container className="contain" maxWidth={window.innerWidth < 2000 ? "1100px" : "1500px"}  sx={{ maxWidth: `${window.innerWidth < 2000 ? "1100px" : "1500px"}` , height: "auto" , paddingTop: 12, paddingBottom: 15}}>
         
            <BreadcrumbPath
                ancestors={[
                {
                    name: gammaName,
                    path: gamma,
                },
                {
                    name: lineName,
                    path: line,
                },
                {
                    name: familyName,
                    path: family,
                },
                ]}
                className="pt-1"
                current={subfamilyDataSheet?.subfamilyName ?? ""}                                                    
            />  
            <div
                className="mb-3"
                style={{ cursor: "pointer" , marginTop: '-50px', position: 'absolute'}} 
                onClick={() => backLevelPage()}    
            >
                <> 
                    <img src={VetorBack} alt=""  width={30}/>
                </>
            </div>

            {subfamilyDataSheet?.dataSheets.length ?            
            (
                <TabSubFamilyDataSheetsDesktop 
                    subfamilyDataSheet={subfamilyDataSheet}
                    askInformation={askInformation}
                    generatePdf={generatePdf}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    handleImageChange={handleImageChange}
                    selectedItemIndex={selectedItemIndex}
                    />
            ) : null}          

           {selectedItemIndex === 0 ? 
           (                          
                <>
                    <Typography sx={{ mt: 1 , color: '#8B939F' , fontSize: 20}} align="center">
                        {subfamilyDataSheet?.subfamilyName}
                    </Typography>                          
                </>
            ) :  null
            }

            <Box 
                className="container1" 
                display={'flex'} 
                width={'100%'}
                >
                <Box 
                    className="sibiling1" 
                    width={'60%'} 
                    >
                    <>
                        <Box 
                            display={'flex'}
                            paddingY={3}
                            justifyContent={'center'}
                            >                      
                            <img
                                src={imageSelected}
                                alt=""
                                style={{ width: "70%", cursor: "zoom-in"}}
                                onClick={() => openImageViewer()}

                            />
                        </Box>
                        <Box my={2}>
                             <Typography 
                                align="center" 
                                // color={"#C0C0C0"} 
                                color={"#535353a3"} 
                                fontSize={11}
                                >
                                *  {subfamilyDataSheet?.dataSheets?.map((item, index) => (
                                        selectedItemIndex === (index + 1) ? (
                                            item.textFooter2                                        
                                    ) : null
                                ))}
                            </Typography>                           
                        </Box>
                        {subfamilyDataSheet?.dataSheets?.map((item, index) => (
                            selectedItemIndex === (index + 1) ? (
                            <>
                                <div className={`${style.cardContainer}`}>
                                    { 
                                        item.code && 
                                        <Typography 
                                            align="left"
                                            // color={"#C0C0C0"}
                                            color={"#535353a3"}
                                            fontSize={11} 
                                            mb={2}
                                            >
                                            Ref: { item.code }
                                        </Typography>       
                                    }
                                        {item && item?.listColor1 != 0 && item?.listColor1 && 
                                        <>                                           
                                            <div className="row mb-5">
                                                    {item.listColor1 && item.listColor1.map((item, index) => {
                                                        return(
                                                            <div  className={style.cardColor}>
                                                                <img 
                                                                key={index}
                                                                className={style.imageColor}
                                                                src={item} alt="image" />
                                                            </div>
                                                        );
                                                        
                                                    })}                            
                                            </div>                                                
                                        </>                             
                                        }
                                    
                                </div>            
                                <Box marginTop={5} justifyContent={'center'} display={'flex'}> 
                                    <Box
                                        className={`dataSheet`}
                                        textAlign={'left'}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleImageChange(subfamilyDataSheet?.subfamilyMediaUrl, undefined, 0, undefined, undefined, undefined)                                            
                                        }}    
                                    >                                       
                                        <Box 
                                            className={`dataSheetItem`}
                                            >
                                            <img src={`${subfamilyDataSheet.subfamilyMediaUrl}`} width={100}/>                                         
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            className=""                                                                                                    
                                            startIcon={isLoading ? <CircularProgress sx={{ color: "#ffffff" , width: "25px"}}/> : <FileDownloadOutlinedIcon />}                                                               
                                            onClick={() =>  { 
                                                setIsLoading(true);
                                                setTimeout(() => {
                                                    generatePdf(item);
                                                }, 1)
                                            }}                                    
                                            sx={{ 
                                                color: "#ffffff",
                                                backgroundColor: "green",
                                                border: "1px solid green",
                                                borderRadius: 1,                                                                                        
                                                margin: "20px",
                                                maxHeight: " 40px !important",
                                                ":disabled": isLoading ? true : false ,
                                                ":hover": { color: "#ffffff", backgroundColor: "green" }
                                            }}
                                            >                                
                                            <Typography>
                                                { isLoading ? t(`${contactForm}loading`, {ns: NameSpace.COMMON }) : t("dataSheets", {ns: NameSpace.PAGES }) }
                                            </Typography>
                                        </Button> 
                                        <Button
                                            startIcon={<AddIcon sx={{ ":hover" : { color: "#ffffff"}}}/>}
                                            onClick={() => askInformation()}
                                            sx={{ 
                                                color: "#28487A",
                                                backgroundColor: "#ffffff",
                                                border: "1px solid #28487A",
                                                borderRadius: 1,
                                                margin: "20px 0",
                                                ":hover": { color: "white", backgroundColor: "#28487A" }
                                            }}
                                            >
                                                <Typography>
                                                    {t("knowMore", { ns: NameSpace.NAVIGATION })}
                                                </Typography>
                                        </Button> 
                                    </Box>                                      
                                </Box>            
                            </>
                            ) :  null
                        ))}
                    </>
                </Box>
                <Box 
                    className="sibiling2" 
                    width={'40%'}
                >
                    <Box 
                        alignItems={"center"}
                    >
                        <Box 
                            className="product-header"  
                            pt={9.6} 
                            pl={3}
                        >
                        {
                        <>
                            {subfamilyDataSheet?.dataSheets?.map((item, index) => (
                                selectedItemIndex === (index + 1) ? (
                                <>  
                                    {item?.title1 ?
                                        (
                                            <Box 
                                                display={'flex'} 
                                                alignItems={'center'} 
                                            >                  
                                                <Box 
                                                    className="container1" 
                                                    sx={{ mb: 1, width: '100%'}}
                                                >
                                                    <Box 
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <IconButton 
                                                            onClick={() => handleProductClick(0)} 
                                                            size="large">
                                                            {expandIndex === 0 ? <RiSubtractFill /> : <FiPlus />}
                                                        </IconButton>
                                                        <Typography 
                                                            align="left" 
                                                            component={'p'} 
                                                            variant="body2" 
                                                            onClick={() => handleProductClick(0)} 
                                                            style={{ cursor: 'pointer', marginLeft: 1 }}
                                                            sx={{ fontSize: "17px", color: "#345C8D", textTransform: "uppercase", marginTop: '5px'}}    
                                                        >
                                                            {item?.title1}
                                                        </Typography>
                                                    </Box>
                                                    <hr/>
                                                    {
                                                        expandIndex === 0 && (
                                                        <>                                                        
                                                            
                                                            <Typography 
                                                                component={'p'} 
                                                                variant="body2" 
                                                                sx={{ fontSize: "15px"}} 
                                                                dangerouslySetInnerHTML={{ __html: item?.paragraph1 }} 
                                                            />
                                                        </>
                                                    )}                        
                                                </Box>
                                            </Box> 
                                        ) : null
                                    }
                                    
                                    {item?.title2 ?
                                        (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                            >                  
                                                <Box
                                                    className="container1"
                                                    sx={{ mb: 1, width: '100%'}}
                                                >
                                                    <Box
                                                        sx={{
                                                            display:'flex',
                                                            alignItems:'center'
                                                            }}
                                                    >
                                                        <IconButton 
                                                            onClick={() => handleProductClick(1)} 
                                                            size="large">
                                                            {expandIndex === 1 ? <RiSubtractFill /> : <FiPlus />}
                                                        </IconButton>
                                                        <Typography 
                                                            align="left" 
                                                            component={'p'} 
                                                            variant="body2" 
                                                            onClick={() => handleProductClick(1)} 
                                                            style={{ cursor: 'pointer', marginLeft: 1 }}
                                                            sx={{ fontSize: "17px", 
                                                                color: "#345C8D", 
                                                                textTransform: "uppercase", 
                                                                marginTop: '5px'}}    
                                                        >
                                                            {item?.title2}
                                                        </Typography>
                                                    </Box>
                                                    <hr/>
                                                    {
                                                        expandIndex === 1 && (
                                                        <>          
                                                            <Typography
                                                            component={'p'}
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "15px"
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: item?.paragraph2 }} />
                                                        </>
                                                    )}                        
                                                </Box>
                                            </Box> 
                                        ) : null
                                    }
                                
                                    {item?.title3 ?
                                        (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                            >                  
                                                <Box
                                                    className="container1"
                                                    sx={{ mb: 1, width: '100%'}}
                                                >
                                                    <Box 
                                                        sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center' }}
                                                    >
                                                        <IconButton 
                                                            onClick={() => handleProductClick(2)} 
                                                            size="large">
                                                            {expandIndex === 2 ? <RiSubtractFill /> : <FiPlus />}
                                                        </IconButton>
                                                        <Typography 
                                                            align="left" 
                                                            component={'p'} 
                                                            variant="body2" 
                                                            onClick={() => handleProductClick(2)} 
                                                            style={{ cursor: 'pointer', marginLeft: 1 }}
                                                            sx={{ 
                                                                fontSize: "17px", 
                                                                color: "#345C8D", 
                                                                textTransform: "uppercase", 
                                                                marginTop: '5px'
                                                            }}    
                                                        >
                                                            {item?.title3}
                                                        </Typography>
                                                    </Box>
                                                    <hr/>
                                                    {
                                                        expandIndex === 2 && (
                                                        <>          
                                                            <Typography
                                                                component={'p'}
                                                                variant="body2"
                                                                sx={{ fontSize: "15px"}} 
                                                                dangerouslySetInnerHTML={{ __html: item?.paragraph3 }} 
                                                                />
                                                        </>
                                                    )}                        
                                                </Box>
                                            </Box> 
                                        ) : null
                                    }
                                                                
                                    {item?.title4 ?
                                        (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                            >                  
                                                <Box
                                                    className="container1"
                                                    sx={{ mb: 1, width: '100%'}}
                                                >
                                                    <Box 
                                                        sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center' 
                                                        }}
                                                    >
                                                        <IconButton 
                                                            onClick={() => handleProductClick(2)} 
                                                            size="large">
                                                            {expandIndex === 3 ? <RiSubtractFill /> : <FiPlus />}
                                                        </IconButton>
                                                        <Typography 
                                                            align="left" 
                                                            component={'p'} 
                                                            variant="body2" 
                                                            onClick={() => handleProductClick(3)} 
                                                            style={{ cursor: 'pointer', marginLeft: 1 }}
                                                            sx={{ 
                                                                fontSize: "17px", 
                                                                color: "#345C8D", 
                                                                textTransform: "uppercase", 
                                                                marginTop: '5px'
                                                            }}    
                                                        >
                                                            {item?.title4}
                                                        </Typography>
                                                    </Box>
                                                    <hr/>
                                                    {
                                                        expandIndex === 3 && (
                                                        <>          
                                                            <Typography 
                                                                component={'p'} 
                                                                variant="body2" 
                                                                sx={{ fontSize: "15px"}} 
                                                                dangerouslySetInnerHTML={{ __html: item?.paragraph4 }} 
                                                            />
                                                        </>
                                                    )}                        
                                                </Box>
                                            </Box> 
                                        ) : null
                                    }

                                    {item?.title5 ?
                                        (
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                            >                  
                                                <Box 
                                                    className="container1" 
                                                    sx={{ 
                                                        mb: 5, 
                                                        width: '100%'
                                                    }}
                                                    >
                                                    <Box 
                                                        sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center' 
                                                        }}
                                                    >
                                                        <IconButton 
                                                            onClick={() => handleProductClick(2)} 
                                                            size="large">
                                                            {expandIndex === 4 ? <RiSubtractFill /> : <FiPlus />}
                                                        </IconButton>
                                                        <Typography 
                                                            align="left" 
                                                            component={'p'} 
                                                            variant="body2" 
                                                            onClick={() => handleProductClick(4)} 
                                                            style={{ cursor: 'pointer', marginLeft: 1 }}
                                                            sx={{ fontSize: "17px", color: "#345C8D", textTransform: "uppercase", marginTop: '5px'}}    
                                                        >
                                                            {item?.title5}
                                                        </Typography>
                                                    </Box>
                                                    <hr/>
                                                    {
                                                        expandIndex === 4 && (
                                                        <>          
                                                            <Typography
                                                                component={'p'}
                                                                variant="body2"
                                                                sx={{ fontSize: "15px"}} 
                                                                dangerouslySetInnerHTML={{ __html: item?.paragraph5 }} 
                                                            />
                                                        </>
                                                    )}                        
                                                </Box>
                                            </Box> 
                                        ) : null
                                    }   
                                </>
                                ) :  null
                                ))}
                            </>
                        }
                            </Box> 
                        </Box>
                </Box>
            </Box>

            <hr />

            <Box className="" width={'100%'}>                   
            {
                        hasTable === true && 
                        <Box 
                            className="" 
                            display={'block'} 
                            paddingLeft={5} 
                            paddingTop={0}
                        >
                            <Box 
                                alignItems={"center"}
                            >    
                                <Box 
                                    display={'flex'} 
                                    alignItems={'center'} 
                                    justifyContent={'center'}
                                >
                                    <Typography
                                        className="subtitle"
                                        component={'h2'}
                                        variant="h2"
                                        align="center"
                                        sx={{                                    
                                            color: "#345C8D",
                                            fontSize: "25px",
                                            fontWeight: 400,
                                            paddingTop: "14px"
                                        }}
                                    >
                                        {t("technicalInformation", { ns: NameSpace.NAVIGATION})}  
                                    </Typography>
                                    {subfamilyDataSheet?.dataSheets?.map((item, index) => (
                                        selectedItemIndex === (index + 1) ? (                          
                                            <>
                                                <Typography 
                                                align="center"
                                                alignItems="center"
                                                component={'h2'}
                                                variant="h2"
                                                sx={{                                                     
                                                    ml: 2,                                                   
                                                    color: "#345C8D",
                                                    fontSize: "25px",
                                                    fontWeight: 400,
                                                    paddingTop: "14px"
                                                }}
                                                >
                                                   
                                                    -   {item.subtitleText} 
                                                </Typography>                          
                                            </>
                                        ) :  null
                                    ))}
                                </Box>                       
                               
                                {/* <hr style={{ margin: '10px 0 10px 0' }}/> */}
                                <Box
                                    sx={{ 
                                        width: '100%', 
                                        marginTop: '15px !important', 
                                        justifyContent: 'center', 
                                        textAlign: 'center', 
                                        display: 'flex', 
                                        mb: 1
                                    }}
                                >
                                    <div
                                        className={`${style.content_table} ${style.backgroundImageColor} table_container`}
                                    >
                                        <div id="tableId"
                                            className={`${style.table} ${style.backgroundImageColor} responsiveTable`}
                                        >
                                        </div>
                                    </div>
                                </Box>                              
                            </Box>
                          
                        </Box>
                    } 
            </Box>           

           
        </Container>
         {
            familyResult?.subfamilies.length > 1 ?(
            <div className="buttons">
                <div className="button-wrapper">
                    <button 
                    className="ArrowLeft" 
                    onClick={() => handleSubfamilyClick((currentIndexPosition - 1))}
                    style={{ display: `${ currentIndexPosition != 0 ? "" : 'none'}` }} 
                    >
                        <img src={ArrowIcon} alt="arrow"/>
                    </button>
                </div>
                <div className="button-wrapper">
                    <button 
                    className="ArrowRight" 
                    onClick={() => handleSubfamilyClick((currentIndexPosition + 1))}
                    style={{ display: `${ 
                        currentIndexPosition !== lastIndexPosition ? "" : 'none'}` }} 
                    >
                        <img src={ArrowIcon} alt="arrow"/>
                    </button>
                </div>
            </div>
            ) : null
         }
         
         <ContactSpecificForm              
              product={subfamilyDataSheet}
              className="mt-10"
              ref={contactFormRef}
          /> 
            <MediaViewer
            visible={visible}
            onClose={closeImageViewer}            
            activeIndex={activeImageIndex}
            images={listImages}
            />        
        </>

    )
}

export default SubfamilyDesktop;



const buildingTable = (data: DataSheetFeatureWithTranslation[], languages: string) => {

    const table = document.createElement('table');
    table.classList.add(style.responsiveTable);


    // if(data && data.length === 0){
    //     const existingElement = document.getElementById('tableId');
    //     if(existingElement) existingElement.remove();
    // }
          
     const uniqueModels = [...new Set(data.map(item => item.feature.model))];
     uniqueModels.sort((a, b) => a - b);         
    
     const headerRow = document.createElement('tr');
 
     
     const languageToKeyword = {
         "PT": "MODELO",
         "EN": "MODEL",
         "FR": "MODÈLE",
         "ES": "MODELO",
     };
 
     
     const languageResult = languageToKeyword[languages] || "Model"; 
      
     const emptyHeaderCell = document.createElement('th');
     emptyHeaderCell.textContent = languageResult;
     emptyHeaderCell.classList.add(style.greentext);
     emptyHeaderCell.style.textAlign = 'center';
     emptyHeaderCell.style.fontSize = '11px';
     headerRow.appendChild(emptyHeaderCell);
 
     uniqueModels.forEach(model => {
         const headerCell = document.createElement('th');
         headerCell.textContent = model;
         headerCell.style.textAlign = 'center';
         headerCell.style.fontSize = '11px';
         
         headerCell.classList.add(style.tableHeader);
         headerRow.appendChild(headerCell);
     });
      
     table.appendChild(headerRow);
  
     const addedTranslations = new Set();
  
     data.forEach(item => {
         const translation = item.translations[0]?.name; 
          
         if (!addedTranslations.has(translation)) {
             const translationRow = document.createElement('tr');
 
             const translationCell = document.createElement('td');
             translationCell.style.fontSize = '11px';
             translationCell.textContent = translation;
             translationRow.appendChild(translationCell);
 
             uniqueModels.forEach(model => {
                 const matchingItem = data.find(d => d.translations[0]?.name === translation && d.feature.model === model);
                 let value = matchingItem ? matchingItem.feature.value || '-' : '-';
 
                 const valueCell = document.createElement('td');
                 valueCell.style.textAlign = 'center';
                 valueCell.style.fontSize = '11px';
                  
                 if (matchingItem && (matchingItem.feature.id.includes("Mec-06") || matchingItem.feature.id.includes("Mir-06") || matchingItem.feature.id.includes("06"))) {
                     const imageElement = document.createElement('img');
                     imageElement.src = `${value}`;
                    //  imageElement.src = `https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/${value}`;
                     imageElement.width = 60;
                     valueCell.appendChild(imageElement);
                 } else {
                     valueCell.textContent = value;
                 }
                 translationRow.appendChild(valueCell);
             }); 
             
             table.appendChild(translationRow);
              
             addedTranslations.add(translation);
         }
     });
 
  
     const tableContainer = document.getElementById('tableId');
     if (tableContainer) {
         tableContainer.innerHTML = '';
     }
   
     const tableContainerNew = document.getElementById('tableId');
     if (tableContainerNew) {
        tableContainerNew.appendChild(table);
     }
};